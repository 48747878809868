import { CommonModule } from "@angular/common";
import { Button } from "./Button";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Wrap } from "./Wrap";
import { CustomerReview } from "./CustomerReview";
import { ReviewsService } from "../services/reviews/reviews.service";
import { Reviews } from "../model/reviews";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'CustomerReviews',
  standalone: true,
  imports: [Button, CommonModule, CustomerReview, TranslateModule, Wrap],
  template: `
    <section class="my-section pb-6" *ngIf="reviews">
      <div class="pt-7 pb-10 md:pt-10 md:pb-16 xl:pt-12 relative">
        <div class="relative z-10">
          <Wrap>
            <h2 translate="CUSTOMER_REVIEWS_TITLE"
                class="text-[22px] md:text-4xl xl:text-[42px] leading-tight text-center mb-6 md:mb-10 xl:mb-12 px-5 md:px-10 xl:px-12">
            </h2>
          </Wrap>

          <div class="overflow-hidden w-full">
            <div
                class="
                reviews-slider
                flex
                items-start
                overflow-x-auto
                no-scrollbar
                gap-[15px]
                md:gap-[30px]
                pt-2
                pb-5
                px-10 md:px-5 xl:px-10
              "
            >
              <CustomerReview
                  *ngFor="let review of reviews.reviews"
                  [name]="review.author"
                  [published]="review.published"
                  [rating]="review.rating * 2"
                  [content]="review.text"
                  [url]="review.url"
                  [fixedSize]="true"
              ></CustomerReview>
            </div>
          </div>
        </div>

        <div
            class="absolute w-full h-full top-0 left-0 flex items-stretch px-5 md:px-10"
        >
          <div
              class="w-full mx-auto max-w-[1200px] bg-yellow-100 rounded-3xl"
          ></div>
        </div>

        <div
            class="absolute bottom-0 left-0 w-full flex justify-center transform translate-y-1/2"
        >
          <Button
              color="yellow"
              href="https://www.google.com/search?q=Combidesk&ludocid=5509634814121195193#lrd=0x47b814660c4c2e05:0x4c7627ac11d1b6b9,3"
              target="_blank">
            {{ 'CUSTOMER_REVIEWS_BUTTON_GOOGLE' | translate }}
          </Button>
        </div>
      </div>
    </section>
  `
})
export class CustomerReviews implements AfterViewInit, OnInit {
  reviews: Reviews;

  constructor(private reviewsService: ReviewsService) {
  }

  ngOnInit(): void {
    // if this fails, we don't want to see an error in the UI
    this.reviewsService.snoozeMessages<ReviewsService>().getReviews().then(reviews => this.reviews = reviews);
  }

  getItemWidth() {
    return window.innerWidth >= 768 ? 300 : 240;
  }

  getItemGap() {
    return window.innerWidth >= 768 ? 30 : 15;
  }

  ngAfterViewInit() {
    const slider = document.querySelector('.reviews-slider');
    if (slider) {
      const firstInMiddle = this.getItemWidth() + this.getItemGap() - (window.innerWidth - this.getItemWidth()) / 2;
      slider.scrollLeft = Math.max(100, firstInMiddle);
    }
  }
}
