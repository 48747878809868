import { User } from "../model";
import { environment } from "../../environments/environment";
import { sleep } from "./sleep.util";

export const showIntercom = () => {
  (<any>window).Intercom('show');
};

export const showAndPrepopulateIntercom = (text: string) => {
  (<any>window).Intercom('showNewMessage', text);
};

export const bootIntercom = async (language: string, user?: User) => {
  if (environment.intercomAppId) {
    if (!user) {
      user = <User>{};
    }

    // not for Bizcuit
    if (window.location.pathname.indexOf("bizcuit") > -1 ||
        window.location.host.indexOf("bizcuit") > -1) {
      return;
    }

    // Intercom is only attached after the user scrolls (see index.html), so waiting for that to happen
    while (!(<any>window).Intercom) {
      await sleep(1000);
    }

    if (user.cindyLoper) {
      // clear the session
      (<any>window).Intercom('shutdown');
      return;
    }

    console.log("booting intercom user id " + user.intercomUserId + " with email " + user.email + " and hash " + user.intercomHash + " for appid " + environment.intercomAppId);

    (<any>window).Intercom('boot', {
      app_id: environment.intercomAppId,
      // The current logged-in user's full name
      name: user.name,
      // The current logged-in user's email address.
      email: user.email,
      // The current logged-in user's sign-up date as a Unix timestamp.
      created_at: user.registrationTs !== 0 ? user.registrationTs : null,
      // user id
      user_id: user.intercomUserId,
      user_hash: user.intercomHash,
      // language setting
      language_override: language,
      // custom properties
      company_name: user.companyName,
      phone_number: user.telephoneNumber,
      hide_default_launcher: user.cindyLoper
    });
  }
};
